import pdf1 from "./assets/pdfs/documents/2024-06-12-EACC Memo - Classification of Asbestos-Containing Plaster_fnl.pdf";

import pdf3 from "./assets/pdfs/documents/Environmental-Abatement-and-Indoor-Air-Quality-Update-on-Best-Practices-Nov-2018.pdf";

import pdf4 from "./assets/pdfs/documents/Technical_Guideline_to_Abestos_Exposure_Report-EN.pdf";

import pdf5 from "./assets/pdfs/documents/Canada-Gazette-2017-Federal-Asbestos-Regulations-Made-Under-the-Can.pdf";

import pdf6 from "./assets/pdfs/documents/EACC-Memo-Regarding-Infection-Control-Nov-2015-Updated-June-2021.pdf";

import pdf7 from "./assets/pdfs/documents/Letter-to-Stakeholders-Regarding-Project-Specific-Designated-Substances-and-Hazardous-Materials-Survey-Report-July-2015-Updated-June-2021.pdf";

import pdf8 from "./assets/pdfs/documents/Letter-to-Stakeholders-Regarding-Hazardous-Substances-Feb-2014-Updated-June-2023.pdf";

import pdf9 from "./assets/pdfs/documents/EACC-Presentation-Asbestos-in-Soil-Nov-2012-Updated-June-2021.pdf";

import pdf10 from "./assets/pdfs/documents/eacoocaarticle_20101004081720.pdf";

import pdf11 from "./assets/pdfs/documents/WTC-Monitoring-Contract.pdf";

import pdf12 from "./assets/pdfs/documents/Asbestos-Assessments-and-Abatement-Following-Unplanned-or-Accidental-Release-to-the-Environmental-Generally-Accepted-Best-Practices.pdf";

import pdf13 from "./assets/pdfs/documents/EACC-Review-of-Air-Clearance-Requirment-Interpretation-Updated-June-2021.pdf";

export const documents = [
  {
    id: 0,
    title:
      "Classification of the Removal of Asbestos-Containing Plaster, Stucco and Other Hard Surfaces",
    date: "June, 2024",
    link: pdf1,
  },
  {
    id: 2,
    title: "The CSA Asbestos report is now available on their ",
    date: "June, 2021",
    link: "",
    website:
      "https://www.csagroup.org/article/research/asbestos-management-in-canada-assessing-the-need-for-a-national-standard/",
  },
  {
    id: 3,
    title:
      "Environmental Abatement and Indoor Air Quality: Update on Best Practices",
    date: "November, 2018",
    link: pdf3,
  },
  {
    id: 4,
    title:
      "Asbestos: Technical Guideline to Asbestos Exposure (Employment and Social Development Canada)",
    date: "January, 2018",
    link: pdf4,
  },
  {
    id: 5,
    title:
      "Asbestos: Regulations Amending Certain Regulations Made Under the Canada Labour Code",
    date: "June, 2017",
    link: pdf5,
  },
  {
    id: 6,
    title: "EACC Memo Regarding Infection Control",
    date: "November, 2015",
    link: pdf6,
  },
  {
    id: 7,
    title:
      "EACC Letter to Stakeholders Regarding Project-Specific Designated Substances and Hazardous Materials Survey Reports",
    date: "July, 2015",
    link: pdf7,
  },
  {
    id: 8,
    title: "EACC Letter to Stakeholders Regarding Hazardous Substances",
    date: "February 13, 2014",
    link: pdf8,
  },
  {
    id: 9,
    title: "EACO Presentation: Asbestos in Soil",
    subtitle:
      "As prepared and presented by Steven Fulford, EACO President, at the Sites and Spills Expo in November 2012.",
    date: "",
    link: pdf9,
  },
  {
    id: 10,
    title:
      "Article: “Don’t get left holding the bag with designated substances",
    subtitle:
      "Article courtesy of the “Construction Comment”, September 2010 edition.",
    date: "",
    link: pdf10,
  },
  {
    id: 11,
    title:
      "World Trade Center Indoor Dust Cleaning Program Monitoring Contract Scope of Work",
    subtitle: "",
    date: "Issued: June 2009",
    link: pdf11,
  },
  {
    id: 12,
    title:
      "Asbestos Assessments and Abatement Following Unplanned or Accidental Release to the Environment Generally Accepted Best Practices",
    info: "The Environmental Abatement Council of Ontario (EACO), an organization of professional environmental abatement contractors, consultants, suppliers and laboratories, has prepared the following information to outline the essential components of an assessment for asbestos and, if required, procedures for asbestos abatement following an unplanned or accidental release to the outdoor environment or within a building.",
    date: "Issued: April 2009",
    link: pdf12,
  },
  {
    id: 13,
    title: "EACC – Review of Air Clearance Requirements & Interpretation",
    info: "Introduction:",
    bullets: [
      "Genereal Overview",
      "Number of Samples Required",
      "Time Required For Sampling",
      "Interpretation of Forced Air to Dislodge Fibres",
      "Analysis & Interpretation of Clearance Samples",
    ],
    link: pdf13,
  },
];
