import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Hero from "../../../components/Hero/Hero";
import "./GuidelineDownload.scss";

import LeakPDF from "../../../assets/pdfs/guideline/EACC-Performance-Leak-Testing-Guideline-for-HEPA-Filtered-Equipment-June-2021.pdf";

import PreConstructionPDF from "../../../assets/pdfs/guideline/EACC-Pre-Construction-Designated-Substances-and-Hazardous-Materials-Assessments-Guideline.pdf";

import AirPDF from "../../../assets/pdfs/guideline/EACC-Indoor-Air-Quality-Guideline-for-Non-Industrial-Workplaces.pdf";

import PathogenPDF from "../../../assets/pdfs/guideline/EACC-Emerging-and-Existing-Pathogen-Cleaning-Best-Practices-for-Environmental-Professional-Services.pdf";

import VermiculitePDF from "../../../assets/pdfs/guideline/EACC-Vermiculite-Guideline-January-2015.pdf";

import LeadPDF from "../../../assets/pdfs/guideline/EACC Lead Guideline January 2025.docx.pdf";

import HygienePDF from "../../../assets/pdfs/guideline/EACC-Guideline-Hygiene-Practices-for-Construction-Workers-and-Infection-Control.pdf";

import MouldPDF from "../../../assets/pdfs/guideline/EACC-Mould-Guideline-April-2015.pdf";

function GuidelineDownload() {
  const [formName, setFormName] = useState("");
  const [PDF, setPDF] = useState("");
  const params = useParams();

  const paramCheck = () => {
    if (params.form === "guideline-eacc-performance-leak-testing-form") {
      setFormName(
        "Performance Leak Testing Guideline for HEPA Filtered Equipment"
      );
      setPDF(LeakPDF);
    }

    if (params.form === "guidelines-eacc-pre-construction-form") {
      setFormName(
        "Pre-Construction Designated Substances and Hazardous Materials Assessments. Guideline for Construction, Renovation and Demolition Projects"
      );
      setPDF(PreConstructionPDF);
    }

    if (params.form === "guideline-eacc-indoor-air-quality-form") {
      setFormName("Indoor Air Quality Guideline for Non-Industrial Workplaces");
      setPDF(AirPDF);
    }

    if (
      params.form ===
      "guideline-eacc-emerging-and-existing-pathogen-cleaning-form"
    ) {
      setFormName(
        "Emerging and Existing Pathogen Cleaning – Best Practices for Environmental Professional Services"
      );
      setPDF(PathogenPDF);
    }

    if (params.form === "guideline-eacc-vermiculite-form") {
      setFormName("EACC Vermiculite Guideline 2015");
      setPDF(VermiculitePDF);
    }

    if (params.form === "guideline-eacc-lead-form") {
      setFormName(
        "EACC Lead Guideline For Construction, Renovation, Maintenance or Repair"
      );
      setPDF(LeadPDF);
    }

    if (params.form === "guideline-eacc-hygiene-practices-form") {
      setFormName(
        "EACC Guideline Hygiene Practices for Construction Workers and Infection Control 2021"
      );
      setPDF(HygienePDF);
    }

    if (params.form === "guideline-eacc-mould-abatement-form") {
      setFormName("EACC Mould Abatement");
      setPDF(MouldPDF);
    }
  };

  useEffect(() => {
    paramCheck();
  }, []);

  return (
    <>
      {formName && (
        <>
          <Hero title="Guidelines" subtitle={formName} />
          <div className="guide-download">
            <h1 className="guide-download__title">{`Download`}</h1>
            <p className="guide-download__text">
              Click the button below to download the
              <strong>{` ${formName} `}</strong>
              Guideline.
            </p>

            <a
              href={PDF}
              target="_blank"
              className="guide-download__button"
              rel="noreferrer"
            >
              DOWNLOAD
            </a>
          </div>
        </>
      )}
    </>
  );
}
export default GuidelineDownload;
