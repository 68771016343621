import "./GuidelineForm.scss";
import Hero from "../../../components/Hero/Hero";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { database } from "../../../firebase";
import { v4 as uuidv4 } from "uuid";
import { sendFormEmail } from "../../../utils/apiCalls";

function GuidelineForm() {
  const [formName, setFormName] = useState("");
  const params = useParams();

  const shortenFormName = (formName) => {
    switch (true) {
      case formName.includes("Pre-Construction"):
        return "Pre-Construction Guideline";
      case formName.includes("Indoor Air"):
        return "Indoor Air Quality Guideline";
      case formName.includes("Pathogen Cleaning"):
        return "Emerging and Existing Pathogen Cleaning";
      case formName.includes("Vermiculite"):
        return "Vermiculite";
      case formName.includes("Lead"):
        return "Lead";
      case formName.includes("Hygiene Practices"):
        return "Hygiene Practices";
      case formName.includes("Mould Abatement"):
        return "Mould Abatement";
      case formName.includes("Leak Testing"):
        return "Leak Testing";
      default:
        return "";
    }
  };

  const paramCheck = () => {
    if (params.form === "guideline-eacc-performance-leak-testing-form") {
      setFormName(
        "Performance Leak Testing Guideline for HEPA Filtered Equipment"
      );
    }

    if (params.form === "guidelines-eacc-pre-construction-form") {
      setFormName(
        "Pre-Construction Designated Substances and Hazardous Materials Assessments. Guideline for Construction, Renovation and Demolition Projects"
      );
    }

    if (params.form === "guideline-eacc-indoor-air-quality-form") {
      setFormName("Indoor Air Quality Guideline for Non-Industrial Workplaces");
    }

    if (
      params.form ===
      "guideline-eacc-emerging-and-existing-pathogen-cleaning-form"
    ) {
      setFormName(
        "Emerging and Existing Pathogen Cleaning – Best Practices for Environmental Professional Services"
      );
    }

    if (params.form === "guideline-eacc-vermiculite-form") {
      setFormName("EACC Vermiculite Guideline 2015");
    }

    if (params.form === "guideline-eacc-lead-form") {
      setFormName(
        "EACC Lead Guideline For Construction, Renovation, Maintenance or Repair"
      );
    }

    if (params.form === "guideline-eacc-hygiene-practices-form") {
      setFormName(
        "EACC Guideline Hygiene Practices for Construction Workers and Infection Control 2021"
      );
    }

    if (params.form === "guideline-eacc-mould-abatement-form") {
      setFormName("EACC Mould Abatement");
    }
  };

  const [fromName, setFromName] = useState("");
  const [fromEmail, setFromEmail] = useState("");
  const [company, setCompany] = useState("");

  const [fromNameError, setFromNameError] = useState(false);
  const [fromEmailError, setFromEmailError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [formError, setFormError] = useState(false);

  const [subSuccessful, setSubSuccessful] = useState(false);

  const inputRef = useRef(null);
  const navigate = useNavigate();

  const sendForm = async (e) => {
    e.preventDefault(e);

    if (fromName.length <= 2) {
      setFromNameError(true);
      return;
    }

    if (company.length <= 2) {
      setCompanyError(true);
      return;
    }

    if (inputRef) {
      console.log(inputRef.current.value);
    }

    if (fromEmail.length <= 3 || !fromEmail.includes("@")) {
      setFromEmailError(true);
      return;
    } else {
      const date = new Date(); // Replace this with your actual Date object

      const templateParams = {
        Name: fromName,
        Email: fromEmail,
        Province: inputRef.current.value,
        Guideline: shortenFormName(formName),
        Company: company,
        Date: date,
      };

      const docRef = doc(database, "Guidelines", uuidv4());

      await setDoc(docRef, templateParams);
      await sendFormEmail(
        shortenFormName(formName),
        fromName,
        company,
        fromEmail,
        inputRef.current.value
      ).then(navigate(`/guidelines/${params.form}/download`));
    }
    // alert("Email successfully sent");
    setSubSuccessful(true);
  };

  useEffect(() => {
    paramCheck();
  }, []);

  return (
    <>
      {formName && (
        <>
          <Hero title="Guidelines" subtitle={formName} />
          <form
            className="guide-form"
            onSubmit={(e) => {
              sendForm(e);
            }}
          >
            <div className="guide-form__divider"></div>
            <p className="guide-form__text">
              Please complete and submit to download the
              <strong>{` ${formName} `}</strong>
              Guideline.
            </p>
            <div className="guide-form__form">
              <h1 className="guide-form__title">{`${formName} Download`}</h1>
              <div>
                <div className="guide-form__input-wrapper">
                  <input
                    className="guide-form__input guide-form__input"
                    type="text"
                    placeholder="Name"
                    onChange={(e) => setFromName(e.target.value)}
                  />
                  <input
                    className="guide-form__input guide-form__input--email "
                    type="email"
                    placeholder="Email Address"
                    onChange={(e) => setFromEmail(e.target.value)}
                  />
                </div>
                <input
                  className="guide-form__input"
                  type="text"
                  placeholder="Company"
                  onChange={(e) => setCompany(e.target.value)}
                />

                <select
                  className="guide-form__input guide-form__input--province"
                  ref={inputRef}
                >
                  <option value="" disabled selected>
                    Province
                  </option>
                  <option value="Alberta">Alberta</option>
                  <option value="British Columbia">British Columbia</option>
                  <option value="Manitoba">Manitoba</option>
                  <option value="New Brunswick">New Brunswick</option>
                  <option value="Newfoundland and Labrador">
                    Newfoundland and Labrador
                  </option>
                  <option value="Northwest Territories">
                    Northwest Territories
                  </option>
                  <option value="Nova Scotia">Nova Scotia</option>
                  <option value="Nunavut">Nunavut</option>
                  <option value="Ontario">Ontario</option>
                  <option value="Prince Edward Island">
                    Prince Edward Island
                  </option>
                  <option value="Quebec">Quebec</option>
                  <option value="Saskatchewan">Saskatchewan</option>
                  <option value="Yukon">Yukon</option>
                  <option value="Other">Other</option>
                </select>

                <button className="guide-form__button">SUBMIT</button>
              </div>
            </div>

            <div className="guide-form__divider"></div>
          </form>
        </>
      )}
    </>
  );
}
export default GuidelineForm;
