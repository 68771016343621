import "./ContactUs.scss";
import phone from "../../../assets/icons/phone.png";
import mail from "../../../assets/icons/mail.png";
import gps from "../../../assets/icons/gps.png";
import twitter from "../../../assets/icons/twitter-white.svg";
import linkedin from "../../../assets/icons/linkedin-white.svg";
import { useState } from "react";
import { sendContactEmail } from "../../../utils/apiCalls";

function ContactUs() {
  const [fromName, setFromName] = useState("");
  const [fromEmail, setFromEmail] = useState("");
  const [message, setMessage] = useState("");
  const [fromNameError, setFromNameError] = useState(false);
  const [fromEmailError, setFromEmailError] = useState(false);
  // const [companyError, setCompanyError] = useState(false);
  // const [formError, setFormError] = useState(false);
  const [subSuccessful, setSubSuccessful] = useState(false);

  const sendForm = async (e) => {
    e.preventDefault(e);

    if (fromName.length <= 2) {
      setFromNameError(true);
      return;
    }

    if (fromEmail.length <= 3 || !fromEmail.includes("@")) {
      setFromEmailError(true);
      return;
    } else {
      await sendContactEmail(fromName, fromEmail, message).then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
    }
    // alert("Email successfully sent");
    setSubSuccessful(true);
  };

  return (
    <div className="contact">
      <div className="contact__container">
        <div className="contact__reachus">
          <h4 className="contact__title">Reach Us</h4>
          <div className="contact__info-wrapper">
            <img className="contact__icon" src={gps} alt="GPS Pin icon" />
            <a
              className="contact__link"
              href="https://www.google.ca/maps/place/70+Leek+Crescent,+Richmond+Hill,+ON+L4B+1H1/@43.8598427,-79.3810818,17z/data=!3m1!4b1!4m5!3m4!1s0x89d4d4d31f4efc0f:0x3f0465926b9e016f!8m2!3d43.8598389!4d-79.3788931?shorturl=1"
              target="_blank"
              rel="noreferrer"
            >
              <p className="contact__text">70 Leek Crescent,</p>
              <p className="contact__text">Richmond Hill, Ontario</p>
              <p className="contact__text">L4B 1H1</p>
            </a>
          </div>
          <div className="contact__info-wrapper">
            <img className="contact__icon" src={phone} alt="Phone Icon" />
            <p className="contact__text">416-499-4000</p>
          </div>
          <div className="contact__info-wrapper">
            <img className="contact__icon" src={mail} alt="Envelope Icon" />
            <a className="contact__text" href="mailto:info@eaccan.com">
              info@eaccan.com
            </a>
          </div>

          <div className="contact__icon-wrapper">
            <a
              href="https://twitter.com/EACCanada"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="contact__social-icon"
                src={twitter}
                alt="Twitter icon"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/environmental-abatement-council-of-canada/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="contact__social-icon contact__social-icon--linked"
                src={linkedin}
                alt="LinkedIn Icon"
              />
            </a>
          </div>
        </div>

        {subSuccessful ? (
          <div className="contact__success">
            <h2 className="">Message successfully sent!</h2>
          </div>
        ) : (
          <form className="contact__form" onSubmit={sendForm}>
            <h4 className="contact__title">Leave A Message</h4>

            <input
              className="contact__input"
              type="text"
              placeholder="Name"
              onChange={(e) => setFromName(e.target.value)}
              required
            />

            <input
              className="contact__input"
              type="email"
              placeholder="Email Address"
              onChange={(e) => setFromEmail(e.target.value)}
              required
            />

            <textarea
              className="contact__input contact__input--textarea"
              name=""
              id=""
              placeholder="Message "
              onChange={(e) => setMessage(e.target.value)}
              required
            />

            <button className="contact__button">SEND MESSAGE</button>
          </form>
        )}
      </div>
    </div>
  );
}
export default ContactUs;
