import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/FooterComponents/Footer/Footer";
import Nav from "./components/NavComponents/Nav/Nav";
import { ScrollToTop } from "./Controller/ScrollToTop";
import ContactPage from "./pages/ContactPage/ContactPage";
import HomePage from "./pages/HomePage/HomePage";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import GuidelinesPage from "./pages/GuidelinesPage/GuidelinesPage";
import DocumentsPage from "./pages/DocumentsPage/DocumentsPage";
import JoinPage from "./pages/JoinPage/JoinPage";
import EventsPage from "./pages/EventsPage/EventsPage";
import BoardPage from "./pages/BoardPage/BoardPage";
import BoardDatesPage from "./pages/BoardDatesPage/BoardDatesPage";
import BoardMinutesPage from "./pages/BoardMinutesPage/BoardMinutesPage";
import FaqPage from "./pages/FaqPage/FaqPage";
import Covid19Page from "./pages/Covid19Page/Covid19Page";
import RadonPage from "./pages/RadonPages/RadonPage";
import Radon101 from "./pages/RadonPages/Radon101/Radon101";
import RadonMembers from "./pages/RadonPages/RadonMembers/RadonMembers";
import RadonResources from "./pages/RadonPages/RadonResources/RadonResources";
import DrugPage from "./pages/DrugPage/DrugPage";
import DrugResources from "./pages/DrugPage/DrugResources/DrugResources";
import JobPostingPage from "./pages/JobPostingPage/JobPostingPage";
import PhotoGalleryPage from "./pages/PhotoGalleryPage/PhotoGalleryPage";
import SingleGallery from "./components/Gallery/SingleGallery/SingleGallery";
import MapPage from "./pages/MapPage/MapPage";
import PressRelease from "./pages/EventsPage/PressRelease/PressRelease";
import MembersPage from "./pages/MembersPage/MembersPage";
import MembersList from "./pages/MembersPage/MembersList/MembersList";
import GuidelineForm from "./pages/GuidelinesPage/GuidelineForm/GuidelineForm";
import GuidelineDownload from "./pages/GuidelinesPage/GuideLineDownload/GuidelineDownload";
import OttawaEventPage from "./pages/OttawaEventPage";
import Committee from "./pages/Committee/Committee";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Nav />
        <Routes>
          <Route path="/" exact element={<HomePage />} />
          <Route path="/about" element={<AboutUsPage />} />

          <Route path="/members" element={<MembersPage />} />
          <Route path="/members/:page" element={<MembersList />} />
          <Route path="members/:page/map" element={<MapPage />} />

          <Route path="/guidelines" element={<GuidelinesPage />} />
          <Route path="/guidelines/:form" element={<GuidelineForm />} />
          <Route
            path="/guidelines/:form/download"
            element={<GuidelineDownload />}
          />

          <Route path="/committee" element={<Committee />} />
          <Route path="/documents" element={<DocumentsPage />} />
          <Route path="/contact-us" element={<ContactPage />} />

          <Route path="/join" element={<JoinPage />} />
          <Route path="/press-release" element={<PressRelease />} />

          <Route path="/news-events" element={<EventsPage />} />
          <Route path="/board-of-directors" element={<BoardPage />} />
          <Route path="/board-meeting-dates" element={<BoardDatesPage />} />
          <Route path="/board-meeting-minutes" element={<BoardMinutesPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/covid-19" element={<Covid19Page />} />

          <Route path="/radon" element={<RadonPage />} />
          <Route path="/radon-101" element={<Radon101 />} />
          <Route path="/radon-certified-members" element={<RadonMembers />} />
          <Route path="/radon-resources" element={<RadonResources />} />

          <Route path="/drug-opioid" element={<DrugPage />} />
          <Route path="/drug-opioid-resources" element={<DrugResources />} />

          <Route path="/job-postings" element={<JobPostingPage />} />
          <Route path="/photos" element={<PhotoGalleryPage />} />
          <Route path="/photos/:id" element={<SingleGallery />} />

          <Route path="/ottawa-event" element={<OttawaEventPage />} />

          {/* <Route path="/webinar" element={<Webinar />} /> */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
