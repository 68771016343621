// const url = "http://127.0.0.1:5001/tca-eacc/us-central1/";
const url = "https://www.eaccan.com/";

export const sendContactEmail = async (from_name, email, message) => {
  try {
    const options = {
      method: "POST",

      body: JSON.stringify({
        from_name,
        email,
        message,
      }),
    };
    const response = await fetch(url + "emailMessage", options);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await response.json();
    console.log(responseData);
    return responseData;
  } catch (error) {
    console.error("Error:", error.message);
    throw error; // Propagate the error further if needed
  }
};

export const sendFormEmail = async (
  form_name,
  from_name,
  company,
  from_email,
  province
) => {
  try {
    const options = {
      method: "POST",

      body: JSON.stringify({
        form_name,
        from_name,
        company,
        from_email,
        province,
      }),
    };
    const response = await fetch(url + "emailFormSubmission", options);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await response.json();
    console.log(responseData);
    return responseData;
  } catch (error) {
    console.error("Error:", error.message);
    throw error; // Propagate the error further if needed
  }
};

export const sendSubEmail = async (from_name, from_email, company) => {
  try {
    const options = {
      method: "POST",

      body: JSON.stringify({
        from_name,
        from_email,
        company,
      }),
    };
    const response = await fetch(url + "emailSubSubmission", options);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await response.json();
    console.log(responseData);
    return responseData;
  } catch (error) {
    console.error("Error:", error.message);
    throw error; // Propagate the error further if needed
  }
};
