import React from "react";
import "../../pages/GuidelinesPage/GuidelinesPage.scss";
import Hero from "../../components/Hero/Hero";
import { Link } from "react-router-dom";
import guidelinePDF from "../../assets/pdfs/webinar/EACC-D-Sub-webinar-pdf.pdf";

function GuidelinesPage() {
  return (
    <>
      <Hero title={"Guidelines"} />
      <div className="guidelines">
        <div className="guidelines__section">
          <h2 className="guidelines__header">
            Guidelines available for download:
          </h2>
          <div className="guidelines__container">
            <div className="guidelines__bin">
              <h3 className="guidelines__title">
                Performance Leak Testing Guideline for HEPA Filtered Equipment –
                2021
              </h3>
              <p className="guidelines__body">
                This guideline promotes industry standards for training,
                equipment, and methodologies for HEPA filtration integrity
                testing of negative air units including construction air
                handling units (CAHU) in CAN/CSA Z317-13 Infection control
                during construction, renovation, and maintenance of health care
                facilities, vacuums and any other HEPA filtered equipment on
                abatement and remediation projects as well as health care
                facility construction, renovation and maintenance projects. This
                guideline is considered a recommended industry standard
                procedure that can be utilized for testing the filtration
                integrity of HEPA filtered equipment used in the control of
                hazardous materials and limited infectious agents (i.e.
                aspergillus, legionella).
              </p>
              <p className="guidelines__issued">Issued: June 2021</p>
            </div>
            <Link
              to="/guidelines/guideline-eacc-performance-leak-testing-form"
              className="guidelines__button"
            >
              <button className="guidelines__download">Download</button>
            </Link>
          </div>

          <div className="guidelines__container">
            <div className="guidelines__bin">
              <h3 className="guidelines__title">
                Pre-Construction Designated Substances and Hazardous Materials
                Assessments. Guideline for Construction, Renovation and
                Demolition Projects.
              </h3>
              <p className="guidelines__body">
                In March 2021, EACC released this guideline which was developed
                for understanding responsibilities involving designated
                substances and/or hazardous materials under the Occupational
                Health and Safety Act and its Regulations prior to the start of
                a construction project. It provides a framework to improve
                worker safety in the general construction, abatement and
                demolition sectors before work starts, and provides information
                and procedures based on current legislation, the state of the
                sciences, industry best practices and practical experience.
              </p>
              <p className="guidelines__issued">Issued: March 2021</p>
              <Link to="/webinar" className="guidelines__link">
                <p className="guidelines__link">{`> Watch the September 16, 2021 webinar about this guide`}</p>
              </Link>
              <a className="guidelines__link" href={guidelinePDF} download>
                <p className="guidelines__link">{`> Download the PowerPoint presentation from the webinar`}</p>
              </a>
            </div>
            <Link
              to="/guidelines/guidelines-eacc-pre-construction-form"
              className="guidelines__button"
            >
              <button className="guidelines__download">Download</button>
            </Link>
          </div>
          <div className="guidelines__container">
            <div className="guidelines__bin">
              <h3 className="guidelines__title">
                Indoor Air Quality Guideline for Non-Industrial Workplaces
              </h3>
              <p className="guidelines__body">
                In July 2020, EACC released the “Indoor Air Quality Guideline
                for Non-Industrial Workplaces” which was prepared to assist
                employers, building owners, real estate management
                professionals, constructors, contractors, and workers to better
                understand typical indoor air quality (IAQ) issues. This
                includes an overview of industry best practices used in both the
                assessment of IAQ issues and preventative measures. It is
                written in the context of non-industrial workplaces, such as
                offices, schools, and commercial retail areas, and is primarily
                intended to address issues related to discomfort, irritation, or
                annoyance rather than health hazards associated with higher
                risks and potential exposures governed under regulated
                occupational exposure limits.
              </p>
              <br></br>
              <p className="guidelines__body">
                The guideline is intended to educate and inform those who need
                an introductory guide to resolve perceived IAQ concerns, and to
                prevent them from occurring.
              </p>
              <p className="guidelines__issued">Issued: July 2020</p>
            </div>
            <Link
              to="/guidelines/guideline-eacc-indoor-air-quality-form"
              className="guidelines__button"
            >
              <button className="guidelines__download">Download</button>
            </Link>
          </div>

          <div className="guidelines__container">
            <div className="guidelines__bin">
              <h3 className="guidelines__title">
                Emerging and Existing Pathogen Cleaning – Best Practices for
                Environmental Professional Services
              </h3>
              <p className="guidelines__body">
                In March 2020, EACC released the guideline document “Emerging
                and Existing Pathogen Cleaning – Best Practices for
                Environmental Professional Services” to offer environmental
                service providers the foundations to make informed decisions
                when responding to calls related to pathogens.
              </p>
              <br></br>
              <p className="guidelines__body">
                It is important to understand the EACC Guideline is NOT
                SPECIFICALLY INTENDED FOR CoVID-19 but rather as a foundation
                document to assist a competent person in developing a scope of
                work following the completion of a site-specific risk assessment
                that considers multiple factors, including the pathogen of
                concern (particle size, severity/type of illness associated with
                infectious agent, primary exposure hazard/route of exposure,
                viability, transmission mode, etc.).
              </p>
              <br></br>
              <p className="guidelines__body">CoVID-19 Information:</p>
              <br></br>
              <p className="guidelines__body">
                * Transmitted mainly via air droplets expelled during coughing
                or sneezing as well as touching surfaces contaminated with the
                virus then touching your eyes, nose or mouth <br></br> * The
                virus may remain viable on a surface from a few hours to a few
                days (depending on the type of surface)<br></br> * Currently
                only one sampling method is available in Ontario specifically
                for CoVID-19
              </p>
              <br></br>
              <h4 className="guidelines__subheader">
                Executive Summary of the Guideline
              </h4>
              <ol className="guidelines__list">
                <li className="guidelines__item">
                  Read the Guideline in its entirety
                </li>
                <li className="guidelines__item">
                  {" "}
                  Understand the risk – preventative, possible or confirmed
                </li>
                <li className="guidelines__item">
                  Adjust the level of precautions to be followed based on the
                  risk assessment
                </li>
                <li className="guidelines__item">
                  Protect your workers using the appropriate personal protective
                  equipment
                </li>
                <li className="guidelines__item">
                  {" "}
                  Protect the area/surrounding area based on the risk assessment
                </li>
                <li className="guidelines__item">
                  Use appropriate DIN registered disinfectant to clean surfaces
                  in the work area
                </li>
                <li className="guidelines__item">
                  Apply disinfectant per manufacturer’s recommendations
                </li>
                <li className="guidelines__item">
                  Have a competent person oversee the work
                </li>
                <li className="guidelines__item">
                  Consider post-cleaning testing based on the risk level
                </li>
                <li className="guidelines__item">
                  Conduct a post-cleaning assessment
                </li>
              </ol>
              <p className="guidelines__body">
                EACC members are professional environmental scientists,
                engineers, consultants, contractors and suppliers. As the
                front-line people dealing with various hazardous materials on a
                daily basis; environmental assessment and response is our main
                focus.
              </p>
              <p className="guidelines__issued">Issued: April 2020</p>
            </div>
            <Link
              to="/guidelines/guideline-eacc-emerging-and-existing-pathogen-cleaning-form"
              className="guidelines__button"
            >
              <button className="guidelines__download">Download</button>
            </Link>
          </div>
          <div className="guidelines__container">
            <div className="guidelines__bin">
              <h3 className="guidelines__title">
                EACC Vermiculite Guideline 2015
              </h3>
              <p className="guidelines__body">
                This guideline has been prepared to assist building owners,
                constructors, contractors, subcontractors and workers who have
                duties under the Occupational Health and Safety Act and its
                Regulations to safely perform work activities involving
                vermiculite. Two key aspects of this document include the
                establishment procedures for the proper sampling and analysis of
                loose vermiculite and the development of work procedures for the
                removal of vermiculite from attics and block walls.
                <br></br>
                <br></br>
                The guideline promotes safe work practices, the use of personal
                protective equipment, worker awareness and training and is based
                in a thorough review of the available guidance materials
                available through December 2014 and professional experience of
                the abatement industry.
                <br></br>
                <br></br>
                We believe that this guideline will not only help employers
                fulfill their responsibility and due diligence under the OSHA
                but will assist them to better address the challenges involved
                with proper assessment and remediation of vermiculite in
                buildings.
              </p>
              <p className="guidelines__issued">Issued: January 2015</p>
            </div>
            <Link
              to="/guidelines/guideline-eacc-vermiculite-form"
              className="guidelines__button"
            >
              <button className="guidelines__download">Download</button>
            </Link>
          </div>
          <div className="guidelines__container">
            <div className="guidelines__bin">
              <h3 className="guidelines__title">
                EACC Lead Guideline For Construction, Renovation, Maintenance or
                Repair
              </h3>
              <p className="guidelines__body">
                This guideline has been prepared to assist building owners,
                constructors, contractors, subcontractors and workers who have
                duties under the Occupational Health and Safety Act and its
                Regulations to safely perform work activities involving
                lead-paints and surface coatings and other lead-containing
                building materials. Two key aspects of this document include the
                establishment of a threshold level for lead in paint and
                classification of common work activates not defined in previous
                lead guidance documents.
                <br></br>
                <br></br>
                The guideline promotes safe work practices, the use of personal
                protective equipment, worker awareness and training and is based
                in a thorough review of the available guidance materials
                available through August 2014 and professional experience of the
                abatement industry.
                <br></br>
                <br></br>
                We believe that this guideline will not only help employers
                fulfill their responsibility and due diligence under the OSHA
                but will assist them to better address the challenges involved
                with proper assessment and remediation of lead in buildings.
              </p>
              <p className="guidelines__issued">Revised: February 2025</p>
            </div>
            <Link
              to="/guidelines/guideline-eacc-lead-form"
              className="guidelines__button"
            >
              <button className="guidelines__download">Download</button>
            </Link>
          </div>
          <div className="guidelines__container">
            <div className="guidelines__bin">
              <h3 className="guidelines__title">
                EACC Guideline Hygiene Practices for Construction Workers and
                Infection Control 2021
              </h3>
              <p className="guidelines__body">
                This guideline has been prepared to assist building owners,
                building managers, constructors, contractors, subcontractors and
                workers, who have duties under the Occupational Health and
                Safety Act and its Regulations, to safely perform work
                activities. The guideline promotes safe work practices, the use
                of personal protective equipment, worker awareness and training
                and is based on a thorough review of regulatory and guidance
                materials available to May 2021, as well as professional
                experience of the abatement and construction industry.
                <br></br>
                <br></br>
                We believe that this guideline will not only help employers
                fulfill their responsibilities and due diligence under the
                Occupational Health and Safety Act but will also assist them to
                better address the challenges involved with implementing the
                appropriate level of construction worker hygiene practices
                during abatement and construction activities.
                <br></br>
                <br></br>
              </p>
            </div>
            <Link
              to="/guidelines/guideline-eacc-hygiene-practices-form"
              className="guidelines__button"
            >
              <button className="guidelines__download">Download</button>
            </Link>
          </div>
          <div className="guidelines__container">
            <div className="guidelines__bin">
              <h3 className="guidelines__title">
                EACC Mould Abatement Guideline
              </h3>
              <p className="guidelines__body">
                This guideline has been prepared to assist building owners,
                constructors, contractors, subcontractors and workers who have
                duties under the Occupational Health and Safety Act and its
                Regulations to safely perform work activities involving Mould
                (Microbial) Abatement and Remediation.
                <br></br>
                <br></br>
                The guideline promotes safe work practices, the use of personal
                protective equipment, worker awareness and training and is based
                in a thorough review of the available guidance materials
                available through November 2003 and professional experience of
                the abatement industry.
                <br></br>
                <br></br>
                We believe that this guideline will not only help employers
                fulfill their responsibility and due diligence under the OSHA
                but will assist them to better address the challenges involved
                with proper assessment and remediation of Mould (microbial)
                contamination in buildings.
              </p>
              <p className="guidelines__issued">Issued: April 2015</p>
            </div>
            <Link
              to="/guidelines/guideline-eacc-mould-abatement-form"
              className="guidelines__button"
            >
              <button className="guidelines__download">Download</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default GuidelinesPage;
