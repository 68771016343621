const prequalifiedContractors = [
  {
    id: 1,
    contacts: [
      {
        company: "A & O Contracting Inc.",
        name: "Anthony Singh",
        phone: "905-828-6868",
        fax: "905-828-0181",
        email: "info@aandocontracting.com",
        address: "2355 Royal Windsor Drive, Unit #6",
        city: "Mississauga",
        province: "ON",
        postal: "L5J 4S8",
        website: "www.aandocontracting.com",
        latitude: 43.50541024406646,
        longitude: -79.63945595111142,
      },
    ],
    logo: "/images/logos/prequalified/AOcontracting.png",
    profile: [
      "Since 2000 A&O Contracting Inc. has been providing quality Environmental Abatement Services to Residential, Industrial, Commercial and Institutional businesses in Ontario. Regardless of size and complexity, we remain fully dedicated from start to finish whilst following all protocols and regulations. Our customer's satisfaction is our achievement.",
    ],
  },
  {
    id: 2,

    contacts: [
      {
        company: "Abbot Environmental Ltd.",
        name: "Patrick Rumball",
        phone: "416-438-5502",
        fax: "416-438-5506",
        email: "patrick@abbottenviro.com",
        address: "445 Midwest Road, Units 27 & 28",
        city: "Scarborough",
        province: "ON",
        postal: "M1P 4Y9",
        website: "www.abbottenviro.com",
        latitude: 43.76451434123597,
        longitude: -79.2721613,
      },
    ],
    logo: "/images/logos/prequalified/abbot.png",
    profile: [
      "Abbot Environmental was launched more than a decade ago with a simple vision: to offer specialized services to clients facing environmental challenges. Abbot is a full-service environmental contracting company. Abbot is known throughout the region for providing environmental abatement services to property owners, general contractors, engineers and government agencies.",
    ],
  },
  {
    id: 3,
    contacts: [
      {
        name: "Dean Power",
        phone: "416-298-4500",
        company: "Alliance Environmental & Abatement Contractors Inc.",
        fax: "416-298-4501",
        email: "dpower@allianceenvironmental.com",
        address: "589 Middlefield Road, Unit 14",
        city: "Scarborough",
        province: "Ontario",
        postal: "M1V 4Y6",
        website: "www.allianceenvironmental.com",
        latitude: 43.81275018910029,
        longitude: -79.25868297309245,
      },
    ],
    logo: "/images/logos/prequalified/alliance.png",
    profile: [
      "Alliance Environmental has been specializing in the abatement and handling of hazardous materials since 2001. We are an industry leader in providing new and innovative ways to exceed industry regulations while completing projects safely and on time. Experience. Expertise. Excellence.",
    ],
    services: "Asbestos, Mould, Vermiculite",
  },

  {
    id: 5,
    contacts: [
      {
        company: "Biggs and Narciso Construction Services Inc.",
        name: "Luis Narciso",
        phone: "905-470-8788",
        fax: "905-470-9102",
        email: "luis@biggsandnarciso.com",
        address: "181 Bentley Street, Unit #14",
        city: "Markham",
        province: "Ontario",
        postal: "L3R 3Y1",
        website: "www.biggsandnarcisco.com",
        latitude: 43.82896469879615,
        longitude: -79.33358047309194,
      },
    ],
    logo: "/images/logos/prequalified/biggs-narcisco.png",
    profile: [
      "Biggs & Narciso has been at the front of the Mould, Lead, Asbestos abetment & Demolition Industry since 1988. Family driven, we focus our efforts on going above and beyond current regulations of the industry; we ensure our client’s needs are met, and provide a supreme commitment to each project.",
    ],
  },

  {
    id: 7,
    contacts: [
      {
        company: "Caliber Environmental Construction Services, Inc.",
        name: "James Ball",
        phone: "905-884-5500",
        fax: "905-884-5515",
        email: "info@caliberenv.com",
        address: "636 Edward Avenue, Unit 7, Bldg. B",
        city: "Richmond Hill",
        province: "Ontario",
        postal: "L4C 0V4",
        website: "www.caliberenv.com",
        latitude: 43.89883660993975,
        longitude: -79.43046427308974,
      },
    ],
    logo: "/images/logos/prequalified/caliber.png",
    profile: [
      "Caliber Environmental is a fully insured, bonded and certified firm that performs asbestos, mould and other hazardous material abatements across all sectors. Caliber is comprised of individuals that have extensive knowledge about hazardous materials, abatement methods and general construction practices to ensure that your abatement project can be completed safely, on time and budget.",
    ],
    services: "Asbestos, Lead, Mould, Vermiculite",
  },
  {
    id: 8,
    contacts: [
      {
        company: "Canadian Industrial Specialties",
        name: "Jonathan Smit",
        phone: "519-336-3013",
        fax: "519-336-8575",
        email: "main@cisenviro.com",
        address: "545 Gladwish Dr.",
        city: "Sarnia",
        province: "Ontario",
        postal: "N7T 7H3",
        website: "www.cisenviro.com",
        latitude: 42.94416316435669,
        longitude: -82.34681487311943,
      },
    ],
    logo: "/images/logos/prequalified/Canadian-Industrial-Specialties.png",
    profile: [
      "An industry  leading Asbestos, Mould and Lead abatement contractor servicing South Western Ontario since 1985.",
    ],
    services: "Asbestos, Lead, Mould, Vermiculite",
  },
  {
    id: 9,
    contacts: [
      {
        company: "CLC Infrastructure Inc.",
        name: "Michael Cicconi",
        phone: "416-646-5199",
        fax: "",
        email: "mcicconi@clcgroup.ca",
        address: "45 LePage Court, Unit B",
        city: "North York",
        province: "Ontario",
        postal: "M3J 2A2",
        website: "www.clcgroup.ca",
        latitude: 43.75838513399135,
        longitude: -79.4855365650863,
      },
    ],
    logo: "/images/logos/prequalified/clc-logo.png",
    profile: [
      "CLC Infrastructure Inc. (CLC) was founded through a merger and acquisition of partnered companies, with a blending of expertise and personnel within the demolition and remediation industry.",
      " With a history spanning more than 30 years, CLC Infrastructure Inc. has established a reputation as a leading Canadian environmental and industrial services provider.",
      " Our services include Demolition & Decommissioning, Environmental Remediation, Excavation & Civil Earth Works, Hazardous Materials Abatement, 24/7 Spill Service, Emergency Management & Environmental Response, and Industrial Cleaning.",

      "Our team will handle all aspects of a project: creation of a work plan, coordination with Owner’s Representatives and Environmental Consultants, mobilization to site, performing removal(s), disposal of material from site, and cleanup of the work area.",
      "At all times, we wish to ensure the highest satisfaction of our customers, and for the projects to run as smoothly as possible. Above all, we adhere to our Health and Safety Policy to protect the well-being of the environment and all personnel involved.",
    ],
  },
  {
    id: "cei",
    contacts: [
      {
        company: "Crozier Environmental Inc.",
        name: "Shelley Benson",
        phone: "905-983-9199",
        fax: "905-983-5976",
        email: "shelley@crozierenviro.com",
        address: "5770 Gamsby Rd",
        city: "Orono",
        province: "Ontario",
        postal: "L0B 1M0",
        website: "www.crozierenviro.com",
        latitude: 43.99047229286389,
        longitude: -78.60153002963325,
      },
    ],
    services:
      "Asbestos, Indoor Air Quality, Lead, Mould, Vermiculite, Animal Feces clean up in attics Ex: Raccoons",
    logo: "/images/logos/general/crozier.png",
    profile: [],

    custom: (
      <>
        <p className="members-component__text">
          Established in 2016 as a full-service Mechanical Contractor, Crozier
          Environmental Inc. holds to a high standard of excellence. Respected
          industry-wide, we are proud of our commitment to excellence. When you
          choose Crozier Environmental Inc., you know you are getting the best
          in experience, in skill, in talent, and most importantly in service.
        </p>
        <br />
        <p className="members-component__text">
          Our team is led by Stephen Crozier who has over 30 years experience in
          the Construction industry and is committed to “Conception to
          Completion” on each project.
        </p>
        <br />
        <p className="members-component__text">
          We introduced a new division in March 2020, which includes services as
          follows:
          <strong>Abatement Remediation: Asbestos, Mould & Lead.</strong> Our
          experienced crew has over 15 years experience.
        </p>
        <br />
        <p className="members-component__text">Our areas of focus include:</p>
        <br />
        <p className="members-component__text">
          • Reduce energy consumption and track performance <br />• Improve air
          quality
          <br /> • Minimize waste by recycling and reusing materials
        </p>
        <br />
        <p className="members-component__text">We will achieve results, by:</p>
        <br />
        <p className="members-component__text">
          • Developing environmental best practices in programs, products, and
          services <br />• Setting environmental impact targets and measuring
          performance <br />• Working with our customers, our employees,
          suppliers, owners, and local communities to minimize our environmental
          impact
        </p>
      </>
    ),
  },
  {
    id: 10,
    contacts: [
      {
        company: "Curmann Contracting Ltd.",
        name: "Fred Topley",
        phone: "416-755-1512",
        fax: "416-755-4140",
        email: "fred@curmann.com",
        address: "88 Northline Road",
        city: "Toronto",
        province: "Ontario",
        postal: "M4B 3E5",
        website: "www.curmann.com",
        latitude: 43.71671383355278,
        longitude: -79.30725070193125,
      },
    ],
    logo: "/images/logos/prequalified/curmann.png",
    profile: [],
  },

  {
    id: 11,
    contacts: [
      {
        company: "Decon Environmental Remediation Inc.",
        name: "Naeem Afzal",
        phone: "905-796-0913",
        fax: "905-795-7710",
        email: "info@deconenviro.com",
        address: "26 Holtby Ave",
        city: "Brampton",
        province: "Ontario",
        postal: "L6X 2M1",
        website: "www.deconenviro.com",
        latitude: 43.681501220132205,
        longitude: -79.7865427730965,
      },
    ],
    logo: "/images/logos/prequalified/decon.png",
    profile: [
      "Decon Environmental Remediation is a leader in property damage restoration and environmental remediation in Southern Ontario. Decon services include mould remediation, asbestos abatement, lead abatement, fire and flood recovery, selective demolition and dry ice blasting.",
    ],
  },
  {
    id: 12,
    contacts: [
      {
        company: "Dewar Industrial Services Inc.",
        name: "Brian Crossan",
        phone: "905-683-5102 ext 5115",
        fax: "905-683-1481",
        email: "brian.crossan@dewar.ca",
        address: "201-1143 Wentworth Street W",
        city: "Oshawa",
        province: "Ontario",
        postal: " L1J 8P7",
        website: "www.dewar.ca",
        latitude: 43.86174528603898,
        longitude: -78.88516900192668,
      },
      {
        company: "Dewar Industrial Services Inc.",
        name: "Bill Donnelly",
        phone: "905-683-5102 ext 5115",
        fax: "905-683-1481",
        email: "bill.donnelly@dewar.ca",
        address: "201-1143 Wentworth Street W",
        city: "Oshawa",
        province: "Ontario",
        postal: " L1J 8P7",
        website: "www.dewar.ca",
        latitude: 43.86174528603898,
        longitude: -78.88516900192668,
      },
    ],
    logo: "/images/logos/prequalified/dewar.png",
    profile: [
      "Dewar is a private Canadian owned general contracting and civil construction company specializing in heavily contaminated abatements including demolitions and providing complex project solutions based out of Oshawa Ontario that services across all of Ontario.",
      " The Dewar brand has been built on a trusted reputation since 1957, providing construction & maintenance services to power generation, institutional, commercial, and industrial clients with a keen focus on safety, quality & performance.",
    ],
  },
  {
    id: 13,
    contacts: [
      {
        company: "Environmental Response Team",
        name: "David Bremner",
        phone: "416-255-6745",
        fax: "416-255-6485",
        email: "nfo@erthazmat.com",
        address: "2850 Lakeshore Blvd W.",
        city: "Etobicoke",
        province: "Ontario",
        postal: "M8V 4A1",
        website: "www.erthazmat.com",
        latitude: 43.601685179123166,
        longitude: -79.50225413077058,
      },
    ],
    logo: "",
    profile: [],
  },
  {
    id: 14,
    contacts: [
      {
        company: "Envirosafe Inc.",
        name: "Shaun Fralic",
        phone: "416-292-1373",
        fax: "416-292-0961",
        email: "shaun@envirosafe.ca",
        address: "80 Nashdene Rd. Unit 51, Bldg. B",
        city: "Scarborough",
        province: "Ontario",
        postal: "M1V 5E4",
        website: "www.envirosafe.ca",
        latitude: 43.81899122030171,
        longitude: -79.253567301928,
      },
    ],
    logo: "",
    services: "Asbestos, Mould",
    profile: [],
  },
  {
    id: 15,
    contacts: [
      {
        company: "Ferro Environmental",
        name: "Peter Ferrante",
        phone: "905-841-8108",
        fax: "905-841-7174",
        email: "peterf@ferrocanada.com",
        address: "955 Alliance Rd.",
        city: "Pickering",
        province: "Ontario",
        postal: "L1W 3M8",
        website: "www.ferrocanada.com",
        latitude: 43.83022665892152,
        longitude: -79.07361742883577,
      },
    ],
    logo: "/images/logos/prequalified/ferro.png",
    profile: [
      "FERRO Environmental is an industry leading full-service environmental contractor using the latest equipment and techniques. We specialize in Asbestos Abatement, Mold Remediation, Soil and Groundwater Remediation, Robotic HVAC Cleaning/Decontamination and Lead Abatement to restore real value to our Client’s bottom line. When you want it done right the first time – call FERRO.",
    ],
  },
  {
    id: 16,
    contacts: [
      {
        company: "Furcon Environmental Inc.",
        name: "Sherry Proulx",
        phone: "905-569-8311",
        fax: "905-569-8317",
        email: "info@furconenviro.com ",
        address: "2495 Haines Road",
        city: "Mississauga",
        province: "Ontario",
        postal: "L4Y 1Y7",
        website: "www.furconenviro.com",
        latitude: 43.5959736293405,
        longitude: -79.59330130193496,
      },
    ],
    logo: "/images/logos/prequalified/furcon.png",
    profile: [
      "Furcon provides various environmental services, including Asbestos Abatement, Mould Remediation, UST/AST Installation, Soil Remediation, Lead Abatement, and Reinsulation.",
    ],
    services: "Asbestos, Mould, Vermiculite ",
  },
  {
    id: 19,
    contacts: [
      {
        company: "I & I Construction Services",
        name: "Ted Barron",
        phone: "905-884-1290",
        fax: "905-884-3267",
        email: "tbarron@iandi.ca",
        address: "70 Newkirk Rd. Unit 6",
        city: "Richmond Hill",
        province: "Ontario",
        postal: "L4C 3G3",
        website: "www.iandi.ca",
        latitude: 43.87996174583864,
        longitude: -79.42799414425458,
      },
    ],
    logo: "",
    profile: [],
  },
  {
    id: 20,
    contacts: [
      {
        company: "Inflector Environmental Services",
        name: "Michael Simmons",
        phone: "613-798-8070",
        fax: "613-798-0908",
        email: "msimmons@inflector.ca",
        address: "6961 McKeown Drive",
        city: "Greely",
        province: "Ontario",
        postal: "K4P 1A2",
        website: "www.inflector.ca",
        latitude: 45.26228979989945,
        longitude: -75.57164052917909,
      },
      {
        company: "Inflector Environmental Services",
        name: "Claudio Belli",
        phone: "416-679-0933",
        fax: "416-675-5978",
        email: "estimatingGTA@inflector.ca",
        address: "98 Milvan Drive",
        city: "Toronto",
        province: "Ontario",
        postal: "M9L 1Z6",
        website: "www.inflector.ca",
        latitude: 43.7554071652256,
        longitude: -79.55955274528331,
      },
      {
        company: "Inflector Environmental Services",
        name: "Daniel Chisholm",
        phone: "902-401-0369",
        fax: "902-481-1912",
        email: "dchisholm@inflector.ca",
        address: "196 Hobsons Lake Drive",
        city: "Halifax",
        province: "Nova Scotia",
        postal: "B3S 0B4",
        website: "www.inflector.ca",
        latitude: 44.641009504045336,
        longitude: -63.67709069059602,
      },
      {
        company: "Inflector Environmental Services",
        name: "Kevin Viggor",
        phone: "780-450-0454",
        fax: "780-450-0462",
        email: "estimatingAB@inflector.ca",
        address: "9730-27 Avenue NW",
        city: "Edmonton",
        province: "Alberta",
        postal: "T6N 1B2",
        website: "www.inflector.ca",
        latitude: 53.457316187068784,
        longitude: -113.47856542470318,
      },
    ],
    logo: "/images/logos/prequalified/IES-logo.png",
    profile: [
      "Inflector Environmental Services is one of Canada’s leading abatement and demolition contractors, offering expert services in asbestos abatement, demolition, mould remediation, soil and site remediation and emergency response. With five offices operating across the country, Inflector is proud to serve a variety of industries including residential, commercial, institutional, government, industrial and nuclear facilities with a diverse range of projects that we specialize in.",
    ],
  },
  {
    id: 21,
    contacts: [
      {
        company: "Inscan Contractors Inc.",
        name: "Chris Paszkat",
        phone: "905-842-5075 ext 25",
        fax: "905-842-5895",
        email: "chris.paszkat@inscancontractors.com",
        address: "341 Dosco Drive",
        city: "Stoney Creek",
        province: "Ontario",
        postal: "L8E 2N6",
        website: "www.inscancontractors.com",
        latitude: 43.44447436769731,
        longitude: -79.69585641475165,
      },
    ],
    logo: "/images/logos/prequalified/inscan.png",
    profile: [
      "Inscan Contractors provides services in the following areas: Nuclear Industry, Industrial/Light Industrial Insulation, Commercial/Institutional Insulation, Asbestos/Mould Abatement, Protective Coatings/Linings & Secondary Containment, Concrete Restoration & Industrial Flooring and Dry Ice Blasting.",
    ],
  },
  {
    id: 23,
    contacts: [
      {
        company: "JMX Environmental Inc.",
        name: "Peter Bensley",
        phone: "905-426-8315",
        fax: "905-426-5418",
        email: "info@jmxenvironmental.com",
        address: "1958 Notion Road",
        city: "Pickering",
        province: "Ontario",
        postal: "L1V 2G3",
        website: "www.jmxenvironmental.com",
        latitude: 43.84994276487652,
        longitude: -79.06486151455,
      },
    ],
    logo: "/images/logos/prequalified/jmx.png",
    profile: [],
  },
  {
    id: 24,
    contacts: [
      {
        company: "Keating Insulation",
        name: "Walter Keating",
        phone: "807-625-5417",
        fax: "807-625-5446",
        email: "walterjr@keatinginc.ca",
        address: "801 Vickers Street North",
        city: "Thunder Bay",
        province: "Ontario",
        postal: "P7C 4B9",
        website: "www.keatinginc.ca",
        latitude: 48.397085263805955,
        longitude: -89.25140565081661,
      },
    ],
    logo: "",
    profile: [],
  },
  {
    id: 25,
    contacts: [
      {
        company: "McGowan Insulations Ltd.",
        name: "Robert McGowan Jr.",
        phone: "905-549-1844",
        fax: "905-664-4884",
        email: "info@mcgowan.on.ca",
        address: "345 Barton Street",
        city: "Stoney Creek",
        province: "Ontario",
        postal: "L8E 2L2",
        website: "www.mcgowan.on.ca",
        latitude: 43.228609300926685,
        longitude: -79.72354772348334,
      },
    ],
    logo: "/images/logos/prequalified/mcgowan.png",
    profile: [
      "McGowan is a private Industrial and Commercial insulation and environmental contracting company that has been established in Hamilton, Ontario since 1972.",
      "McGowan are industry leaders in mechanical insulation, firestop, infection control, mould remediation and asbestos abatement.",
      "McGowan has received the yearly IHSA construction safety award on 10 separate occasions.",
    ],
  },
  {
    id: 26,
    contacts: [
      {
        company: "Ontario Insulation (Oshawa)",
        name: "Judy Connor",
        phone: "905-404-9663",
        fax: "905-404-8413",
        email: "jconnor@ontarioinsulation.ca",
        address: "1300 King St. East P.O. Box 31100",
        city: "Oshawa",
        province: "Ontario",
        postal: "L1H 8N9",
        website: "www.ontarioinsulation.ca",
        latitude: 43.90784899999942,
        longitude: -78.8147968356189,
      },
    ],
    logo: "",
    services: "Asbestos, Lead, Mould, Vermiculite",
    profile: [],
  },
  {
    id: 27,
    contacts: [
      {
        company: "Power Vac Services and Power Environmental Management",
        name: "Norine Jones",
        phone: "905-318-0622",
        fax: "905-318-7069",
        email: " info@powerenv.ca",
        address: "321 Anchor Rd., Unit 2",
        city: "Hamilton",
        province: "Ontario",
        postal: "L8W 3R1",
        website: "www.powerenv.ca",
        latitude: 43.19244095569158,
        longitude: -79.82289128419015,
      },
    ],
    logo: "/images/logos/prequalified/power-environmental.png",
    services:
      "Asbestos, Guano/Animal contaminants, Indoor Air Quality, Lead, Mould, Vermiculite",
    profile: [
      "Power Vac/Power Environmental is a full service environmental contracting company that started in 1966 by providing duct cleaning and general vacuuming services. Our environmental business grew out of our extensive knowledge of air control on asbestos projects. We are now uniquely positioned to provide custom solutions for all environmental projects.",
    ],
  },
  {
    id: 28,
    contacts: [
      {
        company: "QM Environmental",
        name: "Joel Weerdenburg",
        phone: "416-253-6000",
        fax: "416-253-6699",
        email: "Joel.Weerdenburg@QMenv.com",
        address: "200-5035 South Service Road",
        city: "Burlington",
        province: "Ontario",
        postal: "L7L 6M9",
        website: "www.qmenv.com",
        latitude: 43.57233383516911,
        longitude: -79.59471609510489,
      },
    ],
    logo: "/images/logos/prequalified/qm.png",
    profile: [
      "QM Environmental is a full-service decommissioning, environmental contractor and waste management company. With offices across Canada and more than 700 employees, the QMLP team seamlessly provides complete decommissioning, hazardous waste abatement and management, scrap metal salvage and investment recovery, environmental remediation and soil management and emergency response services to all market sectors.",
    ],
  },
  {
    id: 29,
    contacts: [
      {
        company: "R.C. White Ltd.",
        name: "Ted Byrne",
        phone: "519-977-7675",
        fax: "519-977-5202",
        email: "ted@rcwhite.ca",
        address: "3837 Wyandotte St. E",
        city: "Windsor",
        province: "Ontario",
        postal: "N8Y 1G4",
        website: "www.rcwhite.ca",
        latitude: 42.32446309785297,
        longitude: -82.99040981530098,
      },
    ],
    logo: "/images/logos/prequalified/rc-white.png",
    profile: [
      "R.C. White Ltd. offers its Clients a range of services both in the Environmental and Construction sectors: Asbestos including Vermiculite removal, Mould Remediation and refit of buildings after removals are completed, both in Commercial and Residential settings. We also offer floor covering removal for larger interior demolition jobs utilizing our Blastrac Floor Scraping Machine. VAT/VCT, Sheet goods, parquet, epoxy coatings etc. We carry necessary Security Clearance Certificates and Carry both General Liability and Pollution Insurance for our jobs.",
      "We travel throughout Southwestern Ontario to meet your needs.",
    ],
  },
  {
    id: 30,
    contacts: [
      {
        company: "SLC Environmental Inc.",
        name: "Sandy Chapple",
        phone: " 1-888-958-1356",
        fax: "905-581-3194",
        email: "info@slcenvironmental.ca",
        address: "342-1860 Appleby Line, Unit 14",
        city: "Burlington",
        province: "Ontario",
        postal: "L7L 7H7",
        website: "www.slcenivronmental.ca",
        latitude: 43.39127069641833,
        longitude: -79.78807473563113,
      },
    ],
    logo: "/images/logos/prequalified/slc.png",
    profile: [
      "SLC Environmental is a fully independent and operating environmental abatement and remediation company. With over 15 years of experience, we pride ourselves in working with our client to meet their needs. Our service areas include Toronto, GTA, Hamilton, Niagara, KW to London areas of southern Ontario. SLC Environmental is fully insured with Environmental Pollution Insurance and WSIB.",
      "At SLC Environmental – “we strive to make your home, your home again”.",
    ],
  },
  {
    id: 31,
    contacts: [
      {
        company: "Solid General Contractors Inc.",
        name: "Brian Smith",
        phone: "905-475-0707",
        fax: "905-475-1771",
        email: " info@solidgc.ca",
        address: "66 Leek Crescent",
        city: "Richmond Hill",
        province: "Ontario",
        postal: "L4B 1H1",
        website: "www.solidgc.ca",
        latitude: 43.85912542355927,
        longitude: -79.3758599992023,
      },
      {
        company: "Solid General Contractors Inc.",
        name: "Mike Laurie",
        phone: "905-475-0707",
        fax: "905-475-1771",
        email: " 	michael.laurie@solidgc.ca",
        address: "66 Leek Crescent",
        city: "Richmond Hill",
        province: "Ontario",
        postal: "L4B 1H1",
        website: "www.solidgc.ca",
        latitude: 43.85912542355927,
        longitude: -79.3758599992023,
      },
    ],
    logo: "/images/logos/prequalified/solid.png",
    profile: [
      "Solid General Contractors specializes in disaster recovery, restoration, hazardous material remediation, capital and technical projects. Our team of experts and attention to detail today, builds a SOLID tomorrow.",
    ],
  },
  {
    id: 32,
    contacts: [
      {
        company: "Tri-Phase Group Inc.",
        name: "Camille Atrache",
        phone: "905-823-7965",
        fax: "905-823-7932",
        email: "catrache@triphasegroup.com",
        address: "446 Hazelhurst Road",
        city: "Mississauga",
        province: "Ontario",
        postal: "L5J 2Z7",
        website: "www.triphasegroup.com",
        latitude: 43.490263706636085,
        longitude: -79.62696911472902,
      },
    ],
    logo: "/images/logos/prequalified/tpg.png",
    services:
      "Asbestos, Fuel Tanks Removal, Indoor Air Quality, Lead, Mould, Soil Remediation, Vermiculite",
    profile: [
      "Tri-Phase is a privately-owned, Canadian environmental and construction firm. Since our inception in 1997, we have completed thousands of projects related to hazardous materials abatement, demolition, remediation, and construction. We operate across Canada and offer a full range of on-site services to private, public and crown corporations.",
      "We have built a national reputation for providing quality and professional services as well as safe and cost-effective solutions to meet our clients' needs. Tri-Phase's management and operations staff are a competent team comprised of engineers, supervisors, technicians, and equipment operators, all of whom are highly qualified, trained, knowledgeable and experienced individuals.",

      "Our comprehensive, turn-key services include Hazardous Materials Abatement Demolition & Decommissioning Disinfection & Decontamination Geo-Environmental & Geo-Technical Drilling PCB Clean-up & Disposal Soil & Groundwater Remediation Underground & Aboveground Storage Tank Removals/Replacements Emergency Response Recycling Restoration & Repair New Construction Renovations Re-Build Retrofits.",
    ],
  },
  {
    id: 33,
    contacts: [
      {
        company: "Wayne & Harold Smith Construction",
        name: "Harold Smith",
        phone: "519-527-1079",
        fax: "519-527-1040",
        email: " harold@whsmithconstruction.ca",
        address: "55 Birch Street, Box 809",
        city: "Seaforth",
        province: "Ontario",
        postal: "N0K 1W0",
        website: "www.whsmithconstruction.ca",
        latitude: 43.54523188038709,
        longitude: -81.39625118401558,
      },
    ],
    logo: "",
    profile: [],
  },
  {
    id: 34,
    contacts: [
      {
        company: "Zero Environmental",
        name: "Randy Balzer",
        phone: "519-772-5500",
        fax: "",
        email: "info@zeroenvironmental.com",
        address: "100 Hanson Ave., Unit A",
        city: "Kitchener",
        province: "Ontario",
        postal: "N2C 2E2",
        website: "www.zeroenvironmental.com",
        latitude: 43.42649360572722,
        longitude: -80.47238933010374,
      },
    ],
    logo: "/images/logos/prequalified/zero.png",
    services: "Residential Services: Asbestos, Lead, Mould, Vermiculite",
    profile: [
      "Zero Environmental staff and management excel in delivering quality workmanship at a high level of customer service. Zero’s extensive knowledge of industry regulations and guidelines as well as building construction allow them to provide turn-key solutions for their clients. Services offered include asbestos abatement, lead abatement, mould remediation, radon mitigation, biohazard decontamination, dry ice blasting and building envelope assessment and repair. Zero staff have a strong background in providing infection control measures and abatement services in public healthcare facilities. Zero focuses on servicing southwestern Ontario.",
    ],
  },
  {
    id: 35,
    contacts: [
      {
        company: "R-Services Environmental Group Inc.",
        name: "Rafal Tomasik",
        phone: "438-522-5227",
        fax: "",
        email: "rafal@rservicesdemo.com",
        address: "1215 Queensway East, Unit 61",
        city: "Mississauga",
        province: "Ontario",
        postal: "L4Y 1Y6",
        website: "www.rservicesdemo.com",
        latitude: 43.60095569402634,
        longitude: -79.57974445767059,
      },
    ],
    logo: "/images/logos/prequalified/Rservices.png",
    profile: [
      "Over the years, R-Services has established itself as a trusted and reliable partner in providing comprehensive abatement solutions to our valued clients. Whether it's asbestos, lead, mold, or any other hazardous material, we specialize in safe and efficient removal, remediation, and mitigation services. Our team consists of highly skilled and experienced professionals who are dedicated to upholding the highest standards of safety, quality, and compliance. We understand the importance of adhering to all regulations and guidelines set forth by relevant authorities, ensuring that our clients can have peace of mind knowing that their abatement needs are being handled with the utmost care and attention to detail.",
      "At R-Services we take great pride in our ability to tailor our services to meet the unique requirements of each project, regardless of size or complexity. Whether it's residential, commercial, or industrial property, we approach every job with the same level of dedication and professionalism, striving to exceed our clients' expectations at every turn.",
    ],
  },
  {
    id: "Belfor Property Restoration",
    contacts: [
      {
        company: "Belfor Property Restoration",
        name: "Jim Anderson",
        phone: "506 847-4169",
        fax: "",
        email: "jim.anderson@ca.belfor.com",
        address: "11 William Court",
        city: "Quispamsis",
        province: "NB",
        postal: "E2E 4B1",
        website:
          "www.belfor.com/en/ca/belfor-canada-offices/new-brunswick/saint-john",
        latitude: 45.41442447509529,
        longitude: -65.94726501164583,
      },
    ],
    logo: "/images/logos/general/belfor.gif",
    profile: [
      "BELFOR is the world's largest disaster recovery and property damage restoration company operating over 300 locations in 26 countries, with 44 full-service locations in Canada. From structural security and total reconstruction, to the recovery and restoration of documents and machinery, BELFOR Canada has the in-house specialists, capabilities and technologies to handle any type of property damage or disaster. ",

      "In 2010, BELFOR acquired Power Vac Atlantic, a leading duct cleaning and environmental abatement service provider with 7 locations in Atlantic Canada. Today, BELFOR's Atlantic offices are proud to be recognized for expertise in commercial and residential property damage restoration, and as leaders in the Environmental Services industry specializing in Mould Remediation, Lead/Heavy Metals, Infection Control and Asbestos Abatement.",
    ],
  },
];

export default prequalifiedContractors;
